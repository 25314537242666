import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ListenerDTO } from '@/services/LogicEditor/domain/model/Listener'
import ListenerUpdateCommand from '@/services/LogicEditor/application/command/ListenerUpdateCommand'
import ListenerCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ListenerCommandRepositoryInterface'

export default class ListenerUpdateHandler implements CommandHandlerInterface {
    private repository: ListenerCommandRepositoryInterface;

    constructor (repository: ListenerCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ListenerUpdateCommand): Promise<void> {
      let item: ListenerDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        event_id: command.getEventId(),
        listener_cards: command.getListenerCards(),
        listener_commands: command.getListenerCommands()
      }

      return this.repository.update(item)
    }
}
