import CommandHandlerInterface from '@/core/application/command/CommandHandlerInterface'
import { ConstraintDTO } from '@/services/LogicEditor/domain/model/Constraint'
import ConstraintUpdateCommand from '@/services/LogicEditor/application/command/ConstraintUpdateCommand'
import ConstraintCommandRepositoryInterface from '@/services/LogicEditor/domain/repository/ConstraintCommandRepositoryInterface'

export default class ConstraintUpdateHandler implements CommandHandlerInterface {
    private repository: ConstraintCommandRepositoryInterface;

    constructor (repository: ConstraintCommandRepositoryInterface) {
      this.repository = repository
    }

    execute (command: ConstraintUpdateCommand): Promise<void> {
      let item: ConstraintDTO = {
        guid: command.getGuid(),
        name: command.getName(),
        description: command.getDescription(),
        alias: command.getAlias(),
        constraint_type_id: command.getConstraintTypeId(),
        is_auto: command.getIsAuto(),
        is_allow: command.getIsAllow(),
        is_init_allow: command.getIsInitAllow(),
        is_notify: command.getIsNotify(),
        state_match_type: command.getStateMatchType(),
        init_state_match_type: command.getInitStateMatchType(),
        message: command.getMessage(),
        view_fields: command.getViewFields(),
        hide_fields: command.getHideFields(),
        disable_fields: command.getDisableFields(),
        constraint_cards: command.getConstraintCards(),
        constraint_roles: command.getConstraintRoles(),
        constraint_states: command.getConstraintStates(),
        constraint_init_states: command.getConstraintInitStates()
      }

      return this.repository.update(item)
    }
}
