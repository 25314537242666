
export default class EventFactory {
  static async send (
    context: any,
    options: {
      eventType: string,
      entityId?: number|string,
      entityType?: string,
      entityName?: string,
      recordId?: number,
      objectId?: number,
      buttonName?: string,
      registryName?: string,
      authorizationType?: string
    }
  ) {
    await this.waitForSocketConnection(context.$eventSocket, () => context.$eventSocket.send(JSON.stringify({
      'browser': this.checkBrowser(),
      'event_type': options.eventType,
      'entity_id': options.entityId,
      'entity_type': options.entityType,
      'entity_name': options.entityName,
      'record_id': options.recordId,
      'object_id': options.objectId,
      'button_name': options.buttonName,
      'authorization_type': options.authorizationType
    })))
  }

  static checkBrowser () {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
      return ('Opera')
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return ('Edge')
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return ('Chrome')
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return ('Safari')
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return ('Firefox')
    } else if ((navigator.userAgent.indexOf('MSIE') !== -1)) {
      return ('IE')
    } else {
      return ('Неизвестно')
    }
  }

  static waitForSocketConnection (socket, callback, i = 0) {
    let me = this
    if (i === 10) return
    setTimeout(function () {
      if (socket && socket.readyState === 1) {
        if (typeof callback === 'function') {
          callback()
        }
      } else {
        me.waitForSocketConnection(socket, callback, ++i)
      }
    }, 2500)
  }
}
