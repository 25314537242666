















import Vue from 'vue'
import FormMixin from '@/services/NotificationEditor/infrastructure/mixins/FormMixin'
import GroupByIdQuery from "@/services/NotificationEditor/application/query/GroupByIdQuery";
import GroupDTO from "@/services/NotificationEditor/domain/model/GroupDTO";
import GroupCreateCommand from "@/services/NotificationEditor/application/command/GroupCreateCommand";
import GroupUpdateCommand from "@/services/NotificationEditor/application/command/GroupUpdateCommand";

export default Vue.extend({
  name: 'GroupForm',

  mixins: [FormMixin],

  inject: ['getQueryBus', 'getCommandBus'],

  computed: {
    isLoading (): boolean {
      return this.$store.getters['NotificationGroup/isGroupLoading']
    },

    location (): boolean {
      return this.$store.getters['NotificationGroup/getLocation']
    }
  },

  watch: {
    location (value: string): void {
      if (value !== null) {
        const id: number = parseInt(value.replace('/groups/', ''))

        this.getQueryBus()
          .execute(new GroupByIdQuery(id))
          .then((response: GroupDTO) => {
            this.model = response
          })
      }
    }
  },

  data () {
    return {
      rules: {
        name: [
          { required: true, message: 'Field "name" is required', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    executeCommand (callback: Function) {
      this.getCommandBus()
        .execute(
          this.isNew
            ? new GroupCreateCommand(this.model)
            : new GroupUpdateCommand(this.model)
        ).then(() => {
          callback()
        })
    }
  }
})
