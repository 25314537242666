// Core modules
import Vue from 'vue'
import CommandBus from '@/core/application/command/service/CommandBus'
import CommandBusHandlerLocator from '@/core/application/command/service/CommandBusHandlerLocator'
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'

// Query handlers
import RuleByGuidHandler from '@/services/TaskEditor/application/handler/query/RuleByGuidHandler'
import RuleFieldTypesHandler from '@/services/TaskEditor/application/handler/query/RuleFieldTypesHandler'
import RuleGroupByGuidHandler from '@/services/TaskEditor/application/handler/query/RuleGroupByGuidHandler'
import RuleTreeElementByElementGuidHandler from '@/services/TaskEditor/application/handler/query/RuleTreeElementByElementGuidHandler'
import RuleTreeElementByGuidHandler from '@/services/TaskEditor/application/handler/query/RuleTreeElementByGuidHandler'
import RuleTreeElementsHandler from '@/services/TaskEditor/application/handler/query/RuleTreeElementsHandler'
import TaskRegistryEntitiesHandler from '@/services/TaskEditor/application/handler/query/TaskRegistryEntitiesHandler'
import TaskRegistryEntityByPropertyHandler from '@/services/TaskEditor/application/handler/query/TaskRegistryEntityByPropertyHandler'

// Command handlers
import RuleCreateHandler from '@/services/TaskEditor/application/handler/command/RuleCreateHandler'
import RuleUpdateHandler from '@/services/TaskEditor/application/handler/command/RuleUpdateHandler'
import RuleDeleteHandler from '@/services/TaskEditor/application/handler/command/RuleDeleteHandler'
import RuleGroupCreateHandler from '@/services/TaskEditor/application/handler/command/RuleGroupCreateHandler'
import RuleGroupUpdateHandler from '@/services/TaskEditor/application/handler/command/RuleGroupUpdateHandler'
import RuleGroupDeleteHandler from '@/services/TaskEditor/application/handler/command/RuleGroupDeleteHandler'
import TaskRegistryEntityUpdateHandler from '@/services/TaskEditor/application/handler/command/TaskRegistryEntityUpdateHandler'

// Repositories
import RuleCommandRepository from '@/services/TaskEditor/infrastructure/domain/repository/RuleCommandRepository'
import RuleGroupCommandRepository from '@/services/TaskEditor/infrastructure/domain/repository/RuleGroupCommandRepository'
import TaskRegistryEntityCommandRepository from '@/services/TaskEditor/infrastructure/domain/repository/TaskRegistryEntityCommandRepository'

import RuleQueryRepository from '@/services/TaskEditor/infrastructure/domain/repository/RuleQueryRepository'
import RuleGroupQueryRepository from '@/services/TaskEditor/infrastructure/domain/repository/RuleGroupQueryRepository'
import RuleTreeElementQueryRepository from '@/services/TaskEditor/infrastructure/domain/repository/RuleTreeElementQueryRepository'
import TaskRegistryEntityQueryRepository from '@/services/TaskEditor/infrastructure/domain/repository/TaskRegistryEntityQueryRepository'

export default Vue.extend({
  data () {
    return {
      eventBus: new Vue(),

      commandBus: new CommandBus(
        new CommandBusHandlerLocator({
          'RuleCreateCommand': new RuleCreateHandler(
            new RuleCommandRepository()
          ),
          'RuleUpdateCommand': new RuleUpdateHandler(
            new RuleCommandRepository()
          ),
          'RuleDeleteCommand': new RuleDeleteHandler(
            new RuleCommandRepository()
          ),
          'RuleGroupCreateCommand': new RuleGroupCreateHandler(
            new RuleGroupCommandRepository()
          ),
          'RuleGroupUpdateCommand': new RuleGroupUpdateHandler(
            new RuleGroupCommandRepository()
          ),
          'RuleGroupDeleteCommand': new RuleGroupDeleteHandler(
            new RuleGroupCommandRepository()
          ),
          'TaskRegistryEntityUpdateCommand': new TaskRegistryEntityUpdateHandler(
            new TaskRegistryEntityCommandRepository()
          )
        })
      ),

      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'RuleByGuidQuery': new RuleByGuidHandler(
            new RuleQueryRepository()
          ),
          'RuleFieldTypesQuery': new RuleFieldTypesHandler(
            new RuleQueryRepository()
          ),
          'RuleGroupByGuidQuery': new RuleGroupByGuidHandler(
            new RuleGroupQueryRepository()
          ),
          'RuleTreeElementByElementGuidQuery': new RuleTreeElementByElementGuidHandler(
            new RuleTreeElementQueryRepository()
          ),
          'RuleTreeElementByGuidQuery': new RuleTreeElementByGuidHandler(
            new RuleTreeElementQueryRepository()
          ),
          'RuleTreeElementsQuery': new RuleTreeElementsHandler(
            new RuleTreeElementQueryRepository()
          ),
          'TaskRegistryEntitiesQuery': new TaskRegistryEntitiesHandler(
            new TaskRegistryEntityQueryRepository()
          ),
          'TaskRegistryEntityByPropertyQuery': new TaskRegistryEntityByPropertyHandler(
            new TaskRegistryEntityQueryRepository()
          )
        })
      )
    }
  }
})
