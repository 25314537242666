import CKEditor from '@ckeditor/ckeditor5-vue2'
import Editor from '@/components/HTMLEditor/ckeditor.js'
export default {
  inject: {
    openTabModalWindow: {
      default: () => () => {}
    }
  },
  methods: {
    onReady (editor) {
      let me = this
      editor.on('fullscreen', (event, isFullScreen) => {
        me.onFullScreenChange(isFullScreen)
      })
    },
    onFullScreenChange () {
      /*Editor.defaultConfig.toolbar.items = Editor.defaultConfig.toolbar.items.filter((_) => _ !== 'fullscreen')*/
      this.openTabModalWindow(CKEditor.component, {
        editor: Editor, style: 'height: 100%', value: this.localModel
      }, {
        input: (v) => { this.localModel = v },
        ready: (editor) => editor.editing.view.focus()
      })
    }
  }
}
